<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        :title="strTitle"
        divider
        pace="6"
        color="primary1"
        :text-color="strTextColor"
        :html="htmlText"
      >
        <slot />
      </base-info-card>

      <template
        v-for="(
          { strIcon, strIconWhite, arrTexts }, i
        ) in m_arrBusinessContacts"
      >
        <base-avatar-card
          :key="i"
          :src="strTextColor === 'white' ? strIconWhite : strIcon"
          :outlined="false"
          :text-color="strTextColor"
          color="transparent"
          horizontal
          space="0"
        >
          <template v-for="(strText, j) in arrTexts" class="d-flex">
            <div :key="j">
              {{ strText }}
            </div>
          </template>
        </base-avatar-card>

        <v-divider
          v-if="i + 1 !== m_arrBusinessContacts.length"
          :key="`divider-${i}`"
          class="my-2"
          :color="strTextColor"
        />
      </template>
    </div>
  </v-theme-provider>
</template>

<script>
import BusinessDetails from "@/mixins/business-details";

export default {
  name: "KDABusinessCardCol",

  mixins: [BusinessDetails],

  props: {
    dark: Boolean,
    strTitle: {
      type: String,
      default: "ABOUT US",
    },
    htmlText: {
      type: String,
      default: "",
    },
    strTextColor: String,
  },

  computed: {
    m_arrBusinessContacts() {
      return this.g_arrBusinessContacts;
    },
  },
};
</script>
